<template><div>
  <loading :active.sync="this.isLoading"
    :can-cancel="true"
    :on-cancel="onCancel"
    :is-full-page="fullPage"></loading>

  <form @submit.prevent="onsubmit">
    <va-input
      v-model="email"
      type="email"
      :label="$t('auth.email')"
      :error="!!emailErrors.length"
      :error-messages="emailErrors"
    />

    <va-input
      v-model="password"
      type="password"
      :label="$t('auth.password')"
      :error="!!passwordErrors.length"
      :error-messages="passwordErrors"
    />

    <div class="d-flex justify--center mt-3">
      <va-button type="submit" class="my-0">{{ $t('auth.login') }}</va-button>
    </div>
  </form>
</div>
</template>

<script>
import authService from '../../../services/Commons/AuthService'
import store from '../../../store'
import Loading from 'vue-loading-overlay'

export default {
  name: 'login',
  components: {
    Loading,
  },
  data () {
    return {
      email: '',
      password: '',
      keepLoggedIn: false,
      emailErrors: [],
      passwordErrors: [],
    }
  },
  computed: {
    formReady () {
      return !this.emailErrors.length && !this.passwordErrors.length
    },
    isLoading: () => store.getters.isLoading,
  },
  mounted: function () {
    store.commit('setLoading', false)
  },
  methods: {
    async onsubmit () {
      this.emailErrors = this.email ? [] : ['Email is required']
      this.passwordErrors = this.password ? [] : ['Password is required']
      if (!this.formReady) {
        return
      }

      const response = (await authService.login({ email: this.email, password: this.password, grecaptcha: 'xTUTUHOMOLOG#02#07*' })).data.data
      this.$store.dispatch('setLogout')
      this.$store.dispatch('setLogin', { ...response, email: this.email })

      this.$router.push({ name: 'dashboard' })
    },
  },
}
</script>

<style lang="scss">
</style>
